<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 분류 -->
          <c-select
            codeGroupCd='RISK_HAZARD_CLASS_CD'
            type="search"
            itemText="codeName"
            itemValue="code"
            name="riskHazardClassCd"
            label="분류"
            v-model="searchParam.riskHazardClassCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="유해위험요인 목록"
      tableId="riskHazrd"
      :columns="grid.columns"
      :data="grid.data"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" :showLoading="false" label="추가" icon="add" @btnClicked="add" />
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="PUT"
            label="저장" 
            icon="save" 
            @beforeAction="saveRiskHazard"
            @btnCallback="saveRiskHazardCallback"/>
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'risk-hazard',
  data() {
    return {
      searchParam: {
        riskHazardClassCd: null,
        useFlag: 'Y',
      },
      grid: {
        columns: [],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.saas.riskHazard.list.url
      this.saveUrl = transactionConfig.saas.riskHazard.update.url
      // code setting
      this.$comm.getComboItems('RISK_HAZARD_CLASS_CD').then(_result => {
        this.grid.columns = [
          {
            name: 'riskHazardClassCd',
            field: 'riskHazardClassCd',
            label: '유해위험요인 분류',
            align: 'center',
            style: 'width:140px',
            sortable: true,
            required: true,
            type: 'select',
            itemText: 'codeName',
            itemValue: 'code',
            comboItems: _result
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            label: '유해위험요인',
            align: 'left',
            style: 'width:300px',
            sortable: true,
            required: true,
            type: 'text',
          },
          {
            name: 'relevantLegalStandards',
            field: 'relevantLegalStandards',
            label: '관련근거(법적기준)',
            style: 'width:300px',
            align: 'left',
            sortable: true,
            type: 'text',
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:90px',
            sortable: true,
            type: 'check',
            true: 'Y',
            false: 'N',
          },
        ]
      });
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    add() {
      let date = new Date();
      if (!this.grid.data) {
        this.grid.data = [];
      }
      this.grid.data.splice(0, 0, {
        ramRiskHazardId: String(date.getTime()),  // 유해위험요인 번호
        riskHazardName: '',  // 유해위험요인명
        riskHazardClassCd: null,  // 유해위험요인 분류 코드
        relevantLegalStandards: '',  // 관련근거(법적기준)
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        editFlag: 'C'
      })
    },
    saveRiskHazard() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, item => {
              item.regUserId = this.$store.getters.user.userId
              item.chgUserId = this.$store.getters.user.userId
            })
            
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveRiskHazardCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
